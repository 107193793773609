import app from "/src/app/app.module";
import angular from "angular";

class LoginController {

    /** @type {string} */
    token;

    /** @type {string} */
    path;

    /** @type {number} */
    screentype = 0;

    /** @type {{email: string, password: string}} */
    login;

    /**
     * LoginController constructor.
     * @param {$auth} $auth - The authentication service.
     * @param {angular.route.IRouteParamsService} $routeParams - The route parameters service.
     * @param {SettingsService} settings - The settings service.
     * @param {angular.ILocationService} $location - The location service.
     * @param {angular.storage.ILocalStorageService} $localStorage - The local storage service.
     * @param {SweetAlert} sweet - The SweetAlert service.
     * @param {VxUtilsService} vxUtils - The utility service.
     */
    constructor($auth, $routeParams, settings, $location, $localStorage, sweet, vxUtils) {
        // Assign all injected services to this instance
        Object.assign(this, {
            $auth, $routeParams, settings, $location, $localStorage, sweet, vxUtils
        });

        // Initializing local variables
        this.path = this.$location.path();
        const token = this.$routeParams.token;

        if (this.path.indexOf('pssreset') > -1) {
            this.screentype = 1;
        } else if (this.path.indexOf('new') > -1) { // Assuming this is supposed to be different from the 'pssreset' check
            this.screentype = 2;
        }

        this.login = {
            email: this.$localStorage.lastLogin || '',
            password: ''
        };

        this.token = token; // Assign token to this instance for use in methods
    }

    /**
     * Requests a new password.
     */
    requestNewPassword() {
        if (!this.login.email) {
            this.sweet.show({
                title: this.vxUtils.loc('EMAIL_REQUIRED_TITLE'), // Placeholder for translation
                text: this.vxUtils.loc('EMAIL_REQUIRED_TEXT'),  // Placeholder for translation
                type: 'error',
                confirmButtonText: this.vxUtils.loc('OK'),  // Placeholder for translation
                closeOnConfirm: true
            });
        } else {
            this.sweet.show({
                title: this.vxUtils.loc('CONFIRMPASSWORDREQUEST'),
                text: this.vxUtils.loc('PASSWORDRESETTEXT').replace('$email', this.login.email),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: this.vxUtils.loc('YES'),
                closeOnConfirm: true
            }, () => {
                this.$auth.requestReset((data) => {
                    this.sweet.show(this.vxUtils.loc('SUCCES'), this.vxUtils.loc('SUCCEFULL_PASS_RESET').replace('$email', this.login.email), 'success');
                }, this.login.email);
            });
        }
    }


    /**
     * Logs into the application.
     */
    loginToApp() {
        if (this.screentype === 0) {
            this.$auth.login( (data) => {
                this.$localStorage.lastLogin = this.login.email;
            }, angular.toJson(this.login));
        } else if (this.screentype > 0) {
            this.login.token = this.token;
            this.$localStorage.lastLogin = this.login.email;
            this.$auth.reset( (data) => {
                this.sweet.show({
                    title: vxUtils.loc('SUCCES'),
                    text: vxUtils.loc('PASSWORD_RESET_SUCCESSFUL'),
                    type: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#DD6B55',
                    confirmButtonText: vxUtils.loc('PROCEED_TO_LOGIN'),
                    closeOnConfirm: true
                }, ()=> {this.$location.path('/login');});

            }, angular.toJson(this.login), response=>{
                if(response.status === 404){
                    this.sweet.show(this.vxUtils.loc('ERROR'), this.vxUtils.loc('INVALID_TOKEN'), 'error');
                }else {
                    this.sweet.show(this.vxUtils.loc('ERROR'), this.vxUtils.loc('UNKOWN_ERROR'), 'error');
                }

            });
        }
    }

}

// Register the controller with the module
app.controller('login', LoginController);
